<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<div class="md-layout-item md-size-100">
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<div class="card-icon">
							<md-icon>info</md-icon>
						</div>
						<h4 class="title">{{ $t("elasticIp.infoTitle") }}</h4>
						<p v-html="$t('elasticIp.infoBody')"></p>
						<br />
					</md-card-header>
				</md-card>
			</div>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("elasticIp.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="$router.push({ name: 'Add Elastic IP' })"
							v-if="hasPermission('marketplace:elastic_ip_add')">
							{{ $t("elasticIp.add") }}
							<md-icon>add</md-icon>
						</md-button>
						<md-button class="md-info md-round" @click="reloadIpsTable()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									v-model="filters.query"
									:placeholder="$t('general.search')"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								md-label="ID"
								md-sort-by="elastic_ip_id"
								style="width: 5%"
								class="centered-content bordered-cell"
								>{{ item.elastic_ip_id }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.ip.label')"
								md-sort-by="subnet"
								style="width: 40%"
								class="centered-content bordered-cell"
								>{{ item.subnet }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.services.label')"
								md-sort-by="subnet"
								style="width: 20%"
								class="centered-content bordered-cell">
								<div v-if="item.sdwan_contract_id.Int64">
									<span class="status-ko"></span> Elastic IP in uso
									<br />
									<small>
										<code
											class="md-primary md-round"
											@click="goToSdwan(item.sdwan_contract_id.Int64)">
											<b>SDWAN</b> id {{ item.sdwan_contract_id.Int64 }}
											<md-icon>open_in_browser</md-icon>
										</code>
									</small>
								</div>
								<div v-else><span class="status-ok"></span> Elastic IP non in uso</div>
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.region.label')"
								md-sort-by="region_name"
								style="width: 25%"
								class="centered-content bordered-cell"
								>{{ item.region_name }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								style="width: 45%"
								class="centered-content bordered-cell">
								<!-- We have to leave this empty div as separator -->
								<div></div>

								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-danger"
									@click.native="handleDelete(item)"
									v-if="hasPermission('marketplace:elastic_ip_delete')">
									<md-tooltip md-direction="top"
										><p v-html="$t('elasticIp.deleteTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">Delete</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("elasticIp.pendingRequests") }}</h4>
					<br />
				</md-card-header>
				<md-card-content>
					<md-table v-if="tablePendingSubnetRequests">
						<md-table-row>
							<md-table-head>ID</md-table-head>
							<md-table-head>Subnet</md-table-head>
							<md-table-head>Region</md-table-head>
						</md-table-row>
						<md-table-row v-for="request in tablePendingSubnetRequests" :key="request.subnet_request_id">
							<md-table-cell>{{ request.subnet_request_id }}</md-table-cell>
							<md-table-cell>/{{ request.subnet }}</md-table-cell>
							<md-table-cell>{{ request.region_name }}</md-table-cell>
						</md-table-row>
					</md-table>
					<div v-else>
						{{ $t("elasticIp.noPendingRequests") }}
					</div>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			// let result = Array.isArray(this.tableIps) ? this.tableIps : [];
			if (this.searchedData.length === 0) {
				return [];
			}
			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			if (!this.searchedData || !this.tableIps) {
				return 0;
			}
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableIps.length;
		},
	},
	data() {
		return {
			currentSort: "subnet",
			currentSortOrder: "asc",
			pagination: {
				perPage: 10,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			footerTable: ["Id", "Subnet", "Region"],
			searchQuery: "",
			propsToSearch: ["Subnet", "Region"],
			tableIps: [],
			searchedData: [],
			fuseSearch: null,
			tablePendingSubnetRequests: [],
			filters: {
				query: "",
			},
		};
	},
	methods: {
		goToSdwan(sdwan_contract_id) {
			this.$router.push({
				name: "List SDWAN",
				query: { search: String(sdwan_contract_id) },
			});
		},
		editElasticIp(item) {
			this.$router.push({
				name: "Edit Elastic IP",
				params: { elasticIp: item },
			});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleDelete(item) {
			Swal.fire({
				title: this.$t("dialogs.delete_confirmation.title"),
				text: this.$t("dialogs.delete_confirmation.content", {
					attribute: this.$t("sidebar.elastic-ip"),
					subject: item.subnet,
				}),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("dialogs.delete_confirmation.cancel"),
				confirmButtonText: this.$t("dialogs.delete_confirmation.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					let userData = TokenService.getUser();
					evoapi
						.get("/customers/" + userData.customerId + "/elasticIp/free/" + item.elastic_ip_id)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							Swal.fire({
								title: this.$t("responses.deleted_success.title"),
								html: this.$t("responses.deleted_success.content", { subject: item.name }),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							});
						})
						.catch((error) => {
							if (error.response) {
								if (error.response.status === 500 && error.response.data.error) {
									// Gestione dell'errore specifico restituito dall'API
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										html:
											this.$t("elastic_ip.delete.error.content") +
											"<code>" +
											error.response.data.error +
											"</code>",
										footer: this.$t("responses.error.footer"),
										customClass: {
											confirmButton: "md-button md-success",
										},
										buttonsStyling: false,
									});
								} else {
									// Gestione di altri tipi di errori
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							} else {
								// Gestione errori di rete o sconosciuti
								// console.log(error);
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableIps.findIndex(
				(tableRow) => tableRow.device_group_id === item.device_group_id
			);
			if (indexToDelete >= 0) {
				this.tableIps.splice(indexToDelete, 1);
			}
			this.reloadIpsTable();
		},
		// reloadIpsTable(){
		//   // Get data from API
		//   let userData = TokenService.getUser();
		//   evoapi.get("/customers/" + userData.customerId + "/elasticIp/listIps/0?onlyMine=true")
		//   .then((response) => {
		//     this.tableIps = response.data.ips;
		//     this.searchedData = response.data.ips;
		//   });
		// },
		reloadIpsTable() {
			return new Promise((resolve, reject) => {
				let userData = TokenService.getUser(); // Assicurati che questo sia il modo corretto per ottenere userData
				evoapi
					.get("/customers/" + userData.customerId + "/elasticIp/listIps/0?onlyMine=true")
					.then((response) => {
						this.tableIps = response.data.ips || [];
						this.searchedData = response.data.ips || [];
						resolve();
					})
					.catch((error) => {
						// console.error("Errore nel caricamento dei dati: ", error);
						reject(error);
					});
			});
		},
		reloadRequestsTable() {
			// Get data from API
			let userData = TokenService.getUser();
			// Recupera le richieste pendenti
			evoapi
				.get(`/customers/${userData.customerId}/elasticIp/listPendingSubnetRequests`)
				.then((response) => {
					this.tablePendingSubnetRequests = response.data.result;
				})
				.catch((error) => {
					// console.error("Errore nel recupero delle richieste pendenti delle subnet:", error);
				});
		},
		searchByQuery(results) {
			if (!results || this.filters.query === "") {
				return results;
			}
			if (this.$route.query.search) {
				// se è presente alla fine di this.filters.query ||threshold0, allora setta la variabile threshold a 0 e rimuovi il ||threshold0 da this.filters.query
				if (this.filters.query.endsWith("||threshold0")) {
					this.filters.query = this.filters.query.replace("||threshold0", "");
					this.fuseSearch = new Fuse(results, {
						keys: ["elastic_ip_id"],
						threshold: 0,
					});
				} else {
					this.fuseSearch = new Fuse(results, {
						keys: ["elastic_ip_id", "subnet", "region_name"],
						threshold: 0.3,
					});
				}
			} else {
				this.fuseSearch = new Fuse(results, {
					keys: ["elastic_ip_id", "subnet", "region_name"],
					threshold: 0.3,
				});
			}
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
	},
	async mounted() {
		// Caricamento iniziale dei dati
		await this.reloadIpsTable();
		// Verifica se c'è un parametro di ricerca nell'URL e applicalo
		if (this.$route.query.search) {
			this.filters.query = this.$route.query.search + "||threshold0";
		}
		// Caricamento delle richieste pendenti
		await this.reloadRequestsTable();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableIps;
				// Fuse search initialization.
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}

.md-table-cell {
	border-right: 1px solid #ccc; /* Aggiungi un bordo a destra di ogni cella */
	text-align: center; /* Assicurati che il testo sia centrato */
}

.md-table-cell:last-child {
	border-right: none; /* Rimuovi il bordo a destra dell'ultima cella */
}

.md-table-head {
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc; /* Aggiungi un bordo anche agli header */
}

.md-table-head:last-child {
	border-right: none;
}

/* Assicurati che le righe siano ben separate */
.md-table-row {
	border-bottom: 1px solid #ccc;
}

/* Stile per i contenitori delle celle e delle intestazioni */
.centered-content {
	text-align: center;
}

/* Stile per le intestazioni della tabella */
.centered-header th {
	text-align: center !important;
}
.md-table-head {
	background-color: #f0f0f0; /* Grigio chiaro */
	text-align: center;
	font-size: 20px;
	border-right: 1px solid #ccc;
}

.status-ok {
	height: 10px;
	width: 10px;
	background-color: green;
	border-radius: 50%;
	display: inline-block;
}

.status-ko {
	height: 10px;
	width: 10px;
	background-color: red;
	border-radius: 50%;
	display: inline-block;
}

.modal-code {
	text-align: left;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
	overflow: scroll;
	height: 500px;
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}
</style>
