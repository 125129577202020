<template>
	<div class="md-layout text-center">
		<div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<form @submit.prevent="handleSubmit">
				<login-card header-color="transparent">
					<md-field class="md-form-group" slot="inputs">
						<md-icon>email</md-icon>
						<label>Email</label>
						<md-input v-model="email" type="email"></md-input>
					</md-field>
					<md-field class="md-form-group" slot="inputs">
						<md-icon>lock_outline</md-icon>
						<label>Password</label>
						<md-input v-model="password" type="password"></md-input>
					</md-field>
					<md-field v-if="otpRequired" class="md-form-group" slot="inputs">
						<md-icon>vpn_key</md-icon>
						<label>Token 2FA</label>
						<md-input v-model="otp_token" type="text"></md-input>
					</md-field>
					<md-button slot="footer" class="md-primary md-round" type="submit">
						{{ $t("login.login") }}
					</md-button>
					<md-button v-if="shouldShowForgotPassword" slot="footer" class="md-primary md-round md-simple" @click.prevent="forgotPassword">
						{{ $t("login.forgot_password") }}
					</md-button>
				</login-card>
				<md-snackbar
					class="custom-snackbar"
					md-position="center"
					:md-duration="duration"
					:md-active.sync="showSnackbar">
					<p>
						<b>{{ snackbarMessageLine1 }}</b>
						<br />{{ snackbarMessageLine2 }}
					</p>
				</md-snackbar>
			</form>
		</div>
	</div>
</template>

<script>
import { LoginCard } from "@/components";
// import { getCurrentDomain } from '@/utils/domainUtils';

export default {
	components: {
		LoginCard,
	},
	props: {
		logo: {
			type: String,
			default: () => process.env.VUE_APP_LOGO_PATH || "./img/logo-login.png",
		},
	},
	data() {
		return {
			email: null,
			password: null,
			showSnackbar: false,
			snackbarMessageLine1: "",
			snackbarMessageLine2: "",
			duration: 5000,
			otp_token: null,
			otpRequired: false,
			// currentDomain: getCurrentDomain()
		};
	},
	mounted() {
		const email = this.$route.query.email;
		const password = this.$route.query.password;
		const otp_token = this.$route.query.otp_token;

		if (email && password) {
			this.email = email;
			this.password = password;
			if (otp_token) {
				this.otp_token = otp_token;
			}
			this.handleSubmit();
		}
	},
	computed: {
		shouldShowForgotPassword() {
			const forgotPasswordEnv = process.env.VUE_APP_FORGOT_PASSWORD
			return forgotPasswordEnv !== undefined && forgotPasswordEnv.toLowerCase() === 'true'
		}
	},
	methods: {
		async handleSubmit() {
			try {
				const { email, password, otp_token } = this;
				this.$store.dispatch("auth/login", { email, password, otp_token }).then(
					() => {
						setTimeout(() => {
							this.$router.push("/");
						}, 800);
					},
					(error) => {
						this.loading = false;
						this.message = (error.response && error.response.data) || error.message || error.toString();

						if (error.response && error.response.data.error === "Missing TOTP token") {
							this.otpRequired = true;
							this.showSnackbar = true;
							this.snackbarMessageLine1 = this.$t("login.missing_totp");
							this.snackbarMessageLine2 = this.$t("login.missing_totp_description");
						} else if (
							error.response &&
							error.response.data.error === "Invalid TOTP token or backup code"
						) {
							this.otpRequired = true;
							this.showSnackbar = true;
							this.snackbarMessageLine1 = this.$t("login.invalid_totp");
							this.snackbarMessageLine2 = this.$t("responses.error.footer");
						} else {
							this.showSnackbar = true;
							this.snackbarMessageLine1 = this.$t("login.invalid_credentials");
							this.snackbarMessageLine2 = this.$t("responses.error.footer");
						}
					}
				);
			} catch (error) {
				if (error.response && error.response.data.error === "Missing TOTP token") {
					this.otpRequired = true;
					this.snackbarMessageLine1 = this.$t("login.missing_totp");
					this.snackbarMessageLine2 = this.$t("login.missing_totp_description");
					this.showSnackbar = true;
				}
				if (error.response && error.response.data.error === "Invalid TOTP token or backup code") {
					this.otpRequired = true;
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("login.invalid_totp");
					this.snackbarMessageLine2 = this.$t("responses.error.footer");
				} else {
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("login.invalid_credentials");
					this.snackbarMessageLine2 = this.$t("responses.error.footer");
				}
			}
		},
		forgotPassword() {
			this.$router.push({ name: "ForgotPassword" }).catch((err) => {
				if (err.name !== "NavigationDuplicated") {
					// console.error(err);
				}
			});
		},
	},
};
</script>

<style scoped>
.custom-snackbar {
	top: 0;
	transform: translate(0, 100%);
	background-color: #ff0000;
	color: #ffffff;
	border-radius: 4px;
	padding: 12px 24px;
	font-size: 18px;
	z-index: 1100;
	transition: all 0.3s ease;
}
</style>
