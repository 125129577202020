<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ tableTitle }}</h4>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search_records')"
									v-model="searchQuery">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								v-for="(header, index) in tableHeaders"
								:key="header"
								:md-label="header"
								md-sort-by="header"
								>{{ item[index] }}</md-table-cell
							>
							<md-table-cell :md-label="$t('fields.name.label')" md-sort-by="name">{{
								item.name
							}}</md-table-cell>
							<md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
							<md-table-cell :md-label="$t('fields.age.label')">{{ item.age }}</md-table-cell>
							<md-table-cell :md-label="$t('fields.salary.label')">{{ item.salary }}</md-table-cell>
							<md-table-cell :md-label="$t('fields.actions.label')">
								<md-button class="md-just-icon md-info md-simple" @click.native="handleLike(item)">
									<md-icon>favorite</md-icon>
								</md-button>
								<md-button class="md-just-icon md-warning md-simple" @click.native="handleEdit(item)">
									<md-icon>dvr</md-icon>
								</md-button>
								<md-button class="md-just-icon md-danger md-simple" @click.native="handleDelete(item)">
									<md-icon>close</md-icon>
								</md-button>
							</md-table-cell>
						</md-table-row>
					</md-table>
					<div class="footer-table md-table">
						<table>
							<tfoot>
								<tr>
									<th v-for="item in footerTable" :key="item.name" class="md-table-head">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">
												{{ item }}
											</div>
										</div>
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	props: ["tableTitle", "tableDataToRender", "tableHeaders", "tableFooters", "propsUsedToSearch"],
	computed: {
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			footerTable: this.tableFooters,
			searchQuery: "",
			propsToSearch: this.propsUsedToSearch,
			tableData: this.tableDataToRender,
			searchedData: [],
			fuseSearch: null,
		};
	},
	methods: {
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleLike(item) {
			Swal.fire({
				title: `You liked ${item.name}`,
				buttonsStyling: false,
				type: "success",
				confirmButtonClass: "md-button md-success",
			});
		},
		handleEdit(item) {
			Swal.fire({
				title: `You want to edit ${item.name}`,
				buttonsStyling: false,
				confirmButtonClass: "md-button md-info",
			});
		},
		handleDelete(item) {
			Swal.fire({
				title: "Sei sicuro?",
				text: `You won't be able to revert this!`,
				type: "warning",
				showCancelButton: true,
				confirmButtonClass: "md-button md-success btn-fill",
				cancelButtonClass: "md-button md-danger btn-fill",
				cancelButtonText: "Annulla",
				confirmButtonText: "Si, eliminalo!",
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.deleteRow(item);
					Swal.fire({
						title: "Deleted!",
						text: `You deleted ${item.name}`,
						type: "success",
						confirmButtonClass: "md-button md-success btn-fill",
						buttonsStyling: false,
					});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},
	},
	mounted() {
		// Fuse search initialization.
		this.fuseSearch = new Fuse(this.tableData, {
			keys: ["name", "email"],
			threshold: 0.3,
		});
	},
	watch: {
		/**
		 * Searches through the table data by a given query.
		 * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
		 * @param value of the query
		 */
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
	},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
</style>
