<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>perm_contact_calendar</md-icon>
					</div>
					<h4 class="title">{{ $t("customers.list.title") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click.native="addCustomerRequest()"
							v-if="hasPermission('admin:customer_add')">
							<p v-html="$t('customers.add')"></p>
							<md-icon>add_business</md-icon>
						</md-button>
						<md-button class="md-info md-round" @click.native="fetchData()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						@md-selected="onSelect"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="pageOption in pagination.perPageOptions"
										:key="pageOption"
										:label="pageOption"
										:value="pageOption">
										{{ pageOption }}
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-input
									v-model="filters.query"
									:placeholder="$t('general.search')"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<!-- * TABLE CONTENT *-->
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_id')"
								md-sort-by="customer_id"
								style="width: 5%"
								class="centered-content bordered-cell">
								{{ item.customer_id }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('customers.list.footer.customer_name')"
								md-sort-by="name"
								class="centered-content bordered-cell">
								{{ item.name }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('customers.list.footer.tenant_name')"
								md-sort-by="tenant_id"
								class="centered-content bordered-cell">
								{{ getTenantName(item.tenant_id) }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								class="centered-content bordered-cell">
								<div></div>
								<md-button
									class="md-just-icon md-simple md-warning"
									@click.native="editCustomer(item)"
									v-if="hasPermission('admin:customer_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('customers.list.action.edit_customer_tooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>edit</md-icon>
										<div style="font-size: xx-small">Edit</div>
									</div>
								</md-button>

								<md-button
									class="md-just-icon md-simple md-danger"
									@click.native="deleteCustomer(item)"
									v-if="hasPermission('admin:customer_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('customers.list.action.delete_customer_tooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">Delete</div>
									</div>
								</md-button>
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>
<script>
import { Pagination } from "@/components";
import TokenService from "@/services/token";
import evoapi from "@/services/evoapi";
import { swalUtils } from "@/mixins/swal";
import { tableMixins } from "@/mixins/pagination";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import { mapGetters } from "vuex";

export default {
	name: "ListCustomers",
	components: {
		Pagination,
	},
	mixins: [swalUtils, tableMixins],
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "customer_id",
			currentSortOrder: "asc",
			tableData: [],
			searchedData: [],
			tenants: {},
			searchQuery: "",
			footerTable: [this.$t("customers.list.footer.customer_name")],
			showCustomerForm: false,
			editingCustomer: null,
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			filters: {
				query: "",
			},
		};
	},
	mounted() {
		if (TokenService.getUser()["role"] === "super-admin")
			evoapi.get(`/tenants`).then((res) => {
				// Supponendo che res.data sia un array di oggetti tenant con proprietà 'id' e 'name'
				res.data.forEach((tenant) => {
					this.tenants[tenant.tenant_id] = tenant.name;
				});
			});
		else {
			evoapi.get(`/tenants/customer/${TokenService.getUser()["customerId"]}`).then((res) => {
				// res.data sia un array con un unico oggetto tenant con proprietà 'id' e 'name'
				this.tenants[res.data.tenant_id] = res.data.name;
			});
		}
		this.fetchData();
	},
	methods: {
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["name", "tenant_name"],
				threshold: 0.3,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			evoapi
				.get(`/customers/${TokenService.getUser()["customerId"]}`)
				.then((res) => {
					if (typeof res.data === "string") {
						this.tableData = JSON.parse(res.data);
					} else {
						this.tableData = res.data;
					}
					this.tableData = res.data;
					this.searchedData = this.tableData;
				})
				.catch((err) => {
					this.swalBasicErrorDialog();
					// console.error("Error while fetching customer data:", err);
				});
		},
		getTenantName(tenantId) {
			return this.tenants[tenantId] || "Loading..."; // restituisci il nome del tenant o un placeholder se non è ancora disponibile
		},
		addCustomerRequest() {
			this.$router.push({ name: "Add Customer" });
		},
		editCustomer(customer) {
			this.$router.push({
				name: "Edit Customer",
				params: { customer: customer, customer_id: customer.customer_id },
			});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		onSelect(items) {
			this.selectedCustomers = [];
			items.forEach((item) => {
				this.selectedCustomers.push(item.customer_id);
			});
		},
		resetForm() {
			this.showCustomerForm = false;
		},
		deleteCustomer(item) {
			Swal.fire({
				title: this.$t("customers.list.action.delete_customer"),
				text: this.$t("customers.list.action.delete_customer_confirm", { value: item.customer_id }),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.reset_password.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					evoapi
						.delete(`/customers/${item.customer_id}`)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							Swal.fire({
								title: this.$t("responses.deleted_success.title"),
								html: this.$t("responses.deleted_success.content", { subject: item.name }),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							});
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.customer_id === item.customer_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				//console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
</style>
