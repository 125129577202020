<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("devices_neighbor.list.title") }}</h4>
					<p v-html="$t('devices_neighbor.list.content')" />
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("neighbour.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button class="md-info md-round" @click.native="refreshList()">
							{{ $t("neighbour.refresh") }}
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						@md-selected="onSelect"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('search_records')"
									v-model="searchQuery">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row
							slot="md-table-row"
							slot-scope="{ item }"
							md-selectable="multiple"
							md-auto-select
							v-if="item.version != ''">
							<md-table-cell
								:md-label="$t('fields.interface.label')"
								md-sort-by="interface"
								style="width: 20%"
								>{{ item.interface }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.mac_address.label')"
								md-sort-by="mac_address"
								style="width: 20%"
								>{{ item.mac_address }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.board_model.label')"
								md-sort-by="board"
								style="width: 20%"
								>{{ item.board }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.routeros_version.label')"
								md-sort-by="version"
								style="width: 20%"
								>{{ item.version }}</md-table-cell
							>
							<md-table-cell :md-label="$t('fields.identity.label')" md-sort-by="identity">{{
								item.identity
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
				<div class="table table-stats">
					<div class="text-right">
						<md-button class="md-success md-round" @click.native="provisionNeighbourRequest()">
							{{ $t("neighbour.complete") }}
							<md-icon>start</md-icon>
						</md-button>
					</div>
				</div>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";

export default {
	props: ["provisioner_id"],
	components: {
		Pagination,
	},
	computed: {
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = [];
				this.searchedData.forEach((value, index) => {
					result.push(value.item);
				});
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "mac_address",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			footerTable: [
				"",
				this.$t("fields.interfaces.label"),
				this.$t("fields.mac_address.label"),
				this.$t("fields.board_model.label"),
				this.$t("fields.routeros_version.short_label"),
				"Identity",
			],
			searchQuery: "",
			propsToSearch: ["interface", "mac_address", "board", "identity"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			selected: [],
		};
	},
	methods: {
		onSelect(items) {
			this.selected = items;
		},
		provisionNeighbourRequest() {
			if (this.selected.length == 0) {
				Swal.fire({
					icon: "error",
					title: this.$t("dialogs.device_provisioning_neighbor_error.title"),
					text: this.$t("dialogs.device_provisioning_neighbor_error.content"),
				});
			} else {
				var devices = [];
				this.selected.forEach((dev) =>
					devices.push({
						mac_address: dev.mac_address,
						version: dev.version.match(/^(\S+)\s(.*)/)[1],
						identity: dev.itendity,
					})
				);
				this.$router.push({
					name: "Add Neighbour Device",
					params: { provisioner_id: this.provisioner_id, devices: devices },
				});
			}
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		initializeDevice(item) {
			const { value: deviceName } = Swal.fire({
				title: this.$t("dialogs.device_neighbor_provisioning_confirmation.title"),
				text: this.$t("dialogs.device_neighbor_provisioning_confirmation.content", {
					subject: item.mac_address,
				}),
				input: "text",
				inputPlaceholder: this.$t("dialogs.device_neighbor_provisioning_confirmation.placeholder"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.device_neighbor_provisioning_confirmation.validation");
					}
				},
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
				},
				cancelButtonClass: "md-button md-danger btn-fill",
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.device_neighbor_provisioning_confirmation.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: this.$t("dialogs.device_neighbor_provisioning_progress.title"),
						html: this.$t("dialogs.device_neighbor_provisioning_progress.content"),
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					let userData = TokenService.getUser();
					evoapi
						.post(
							"/customers/" + userData.customerId + "/provisioners/" + this.provisioner_id + "/devices",
							{
								mac_address: item.mac_address,
								name: result.value,
							}
						)
						.then((response) => {
							Swal.close();
							this.$router.push({ name: "List Devices" });
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							}
						});
				}
			});
		},
		refreshList() {
			// Get data from API
			let userData = TokenService.getUser();
			evoapi
				.get("/customers/" + userData.customerId + "/provisioners/" + this.provisioner_id + "/devices")
				.then((response) => {
					this.tableData = response.data;
					// Fuse search initialization.
					this.fuseSearch = new Fuse(this.tableData, {
						keys: ["interface", "mac_address", "board", "identity"],
						threshold: 0.2,
					});
				});
		},
	},
	mounted() {
		// Get data from API
		let userData = TokenService.getUser();
		evoapi
			.get("/customers/" + userData.customerId + "/provisioners/" + this.provisioner_id + "/devices")
			.then((response) => {
				this.tableData = response.data;
				// Fuse search initialization.
				this.fuseSearch = new Fuse(this.tableData, {
					keys: ["interface", "mac_address", "board", "identity"],
					threshold: 0.2,
				});
			});
	},
	watch: {
		/**
		 * Searches through the table data by a given query.
		 * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
		 * @param value of the query
		 */
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
	},
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
}
.md-table .md-table-head:last-child {
	text-align: right;
}

.table-stats {
	display: flex;
	align-items: center;
	text-align: right;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}
</style>
