<template>
	<md-toolbar
		md-elevation="0"
		class="md-transparent"
		:class="{
			'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
		}">
		<div class="md-toolbar-row">
			<div class="md-toolbar-section-start">
				<!-- <h3 class="md-title">{{ $route.name }}</h3> -->
			</div>
			<div class="md-toolbar-section-end">
				<md-button
					class="md-just-icon md-round md-simple md-toolbar-toggle"
					:class="{ toggled: $sidebar.showSidebar }"
					@click="toggleSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</md-button>

				<!-- Just a separator -->
				<div class="md-collapse"></div>

				<!-- List of element -->
				<md-list>
					<md-list-item>
						<!-- p with small text -->
						<p class="md-caption">
							{{ $t("sidebar.welcome") }} <i>{{ username }}</i> (<b>{{ role }}</b
							>) <br />
							{{ $t("sidebar.selected_customer") }}: <b>{{ selectedCustomerName }}</b> (id:
							{{ selectedCustomer }}) <br />
						</p>
					</md-list-item>
					<!-- Admin customer selection -->
					<md-list-item v-if="is_admin">
						<div class="md-layout-item md-size-100 ml-auto md-small-size-100" style="width: 350px">
							<md-field>
								<label for="customer_search">{{ $t("general.customers") }}</label>
								<md-autocomplete
									v-model="customerSearchText"
									:md-options="filteredCustomers"
									@md-selected="setSelectedCustomer"
									name="customer_search.name"
									id="customer_search.customer_id">
									<template slot="md-autocomplete-item" slot-scope="{ item, index }">
										<div
											:class="{ 'active-item': index === activeIndex }"
											style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
											<b>{{ item.name }}</b> (id: {{ item.customer_id }}) - <i>{{ item.tenant_name }}</i>
										</div>
									</template>
								</md-autocomplete>
							</md-field>
						</div>
					</md-list-item>

					<!-- The locale switcher -->
					<md-list-item>
						<md-menu md-size="medium" md-align-trigger>
							<div md-menu-trigger class="locale-switcher">
								<img
									v-if="locale === 'it'"
									src="@/assets/images/flag-it.svg"
									height="20"
									width="20"
									:alt="locale" />
								<img
									v-else-if="locale === 'en'"
									src="@/assets/images/flag-en.svg"
									height="20"
									width="20"
									:alt="locale" />
								<img v-else src="@/assets/images/flag-en.svg" height="20" width="20" :alt="locale" />
							</div>

							<md-menu-content>
								<md-menu-item v-if="locale !== 'it' || !locale" @click="switchToLocale('it')"
									>IT</md-menu-item
								>
								<md-menu-item v-if="locale !== 'en'" @click="switchToLocale('en')">EN</md-menu-item>
							</md-menu-content>
						</md-menu>
					</md-list-item>

					<!-- Notifications -->
					<md-list-item v-if="hasPermission('notification:notification_list')">
						<a
							href="#/notifications"
							class="md-list-item-router md-list-item-container md-button-clean dropdown">
							<div class="md-list-item-content">
								<drop-down direction="down">
									<md-button
										slot="title"
										class="md-button md-just-icon md-simple"
										data-toggle="dropdown">
										<md-icon>notifications</md-icon>
										<span class="notification" v-if="notification_count > 0">{{
											notification_count
										}}</span>
										<p class="hidden-lg hidden-md">
											{{ $t("general.notifications") }}
										</p>
									</md-button>
								</drop-down>
							</div>
						</a>
					</md-list-item>
				</md-list>
			</div>
		</div>
	</md-toolbar>
</template>

<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			notification_count: 0,
			timer: null,
			is_admin: false,
			role: null,
			username: null,
			customers: [],
			selectedCustomer: null,
			selectedCustomerName: null,
			customerSearch: "",
			customerSearchText: "",
		};
	},
	mounted() {
		let userData = TokenService.getUser();
		this.is_admin = userData?.isAdmin || false;
		this.username = userData?.username || null;
		this.role = userData?.role || null;
		if (this.is_admin) {
			this.selectedCustomer = userData?.customerId || null;
			this.selectedCustomerName = userData?.customerName || null;
			if (this.role == "super-admin") {
				evoapi.get("/customers/0").then((response) => {
					this.customers = response.data;
				});
			} else {
				evoapi.get("/auth/customers").then((response) => {
					this.customers = response.data;
				});
			}
		} else {
			this.selectedCustomer = userData?.customerId || null;
			this.selectedCustomerName = userData?.customerName || null;
		}
		this.checkNotifications();
		this.timer = setInterval(() => {
			this.checkNotifications();
		}, 20 * 1000);
	},
	watch: {
		selectedCustomer: function () {
			let userData = TokenService.getUser();
			if (this.selectedCustomer == parseInt(userData?.customerId, 10)) return;
			userData.customerId = this.selectedCustomer;
			TokenService.setUser(userData);
			this.$router.go();
		},
		selectedCustomerName: function () {
			let userData = TokenService.getUser();
			if (this.selectedCustomerName == userData?.customerName) return;
			userData.customerName = this.selectedCustomerName;
			TokenService.setUser(userData);
			this.$router.go();
		},
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		locale() {
			return this.$store.getters["settings/locale"];
		},
		filteredCustomers() {
			return this.customers.map((customer) => ({
				name: customer.name,
				customer_id: customer.customer_id,
				tenant_name: customer.tenant_name,
				label: `${customer.name} (${customer.customer_id}) - ${customer.tenant_name}`,
				value: customer.customer_id,
				toLowerCase: () => `${customer.name} (${customer.customer_id}) - ${customer.tenant_name}`.toLowerCase(),
				toString: () => `${customer.name} (${customer.customer_id}) - ${customer.tenant_name}`,
			}));
		},
	},
	methods: {
		setSelectedCustomer(selectedOption) {
			this.selectedCustomer = selectedOption.customer_id;
			this.customerSearchText = `${selectedOption.name} (${selectedOption.customer_id}) - ${selectedOption.tenant_name}`;

			let userData = TokenService.getUser(); // Ottieni l'utente corrente
			userData.customerName = selectedOption.name; // Aggiorna anche il nome del cliente selezionato

			TokenService.setUser(userData);
		},
		switchToLocale(locale) {
			this.$store.dispatch("settings/setLocale", locale);
		},
		checkNotifications() {
			let userData = TokenService.getUser();
			const customerId = userData?.customerId || null;
			evoapi.get("/customers/" + customerId + "/notifications/check").then((response) => {
				this.notification_count = response.data.notification_count;
			});
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
};
</script>

<style scoped>
.locale-switcher {
	width: 20px;
	position: relative;
	top: 10px;
}
</style>
