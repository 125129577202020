<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("usage.stats.infoTitle") }}</h4>
					<p v-html="$t('usage.stats.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<!-- Active customers -->
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("usage.stats.activeCustomerInfoTitle") }}</h4>
					<div><br /></div>
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="downloadCsv('active-customer', 0)"
							v-if="hasPermission('usage:stats_download')">
							<p v-html="$t('usage.stats.download')"></p>
							<md-icon>download</md-icon>
						</md-button>
						<md-button
							class="md-warning md-round"
							@click="downloadCsv('active-customer', 30)"
							v-if="hasPermission('usage:stats_download')">
							<p v-html="$t('usage.stats.download30d')"></p>
							<md-icon>download</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="funcActiveCustomerData"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('usage.stats.label.tenant_name')"
								style="width: 12%"
								class="centered-content bordered-cell"
								><i>{{ item.tenant_name }}</i></md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.customer_name')"
								md-sort-by="name"
								style="width: 12%"
								class="centered-content bordered-cell"
								><b>{{ item.customer_name }}</b></md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.device_qty')"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.device }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.sdwan_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.sdwan_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.shield_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.shield_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.additional_ip_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.additional_ip_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.content_filtering_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.content_filtering_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.chraas_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.chraas_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.timestamp')"
								md-sort-by="name"
								style="width: 16%"
								class="centered-content bordered-cell"
								>{{ item.latest_timestamp }}</md-table-cell
							>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
			<!-- All customers -->
			<md-card v-if="hasPermission('usage:stats_all-customers')">
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("usage.stats.allCustomersInfoTitle") }}</h4>
					<div><br /></div>
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="downloadCsv('all-customers', 0)"
							v-if="hasPermission('usage:stats_download')">
							<p v-html="$t('usage.stats.download')"></p>
							<md-icon>download</md-icon>
						</md-button>
						<md-button
							class="md-warning md-round"
							@click="downloadCsv('all-customers', 30)"
							v-if="hasPermission('usage:stats_download')">
							<p v-html="$t('usage.stats.download30d')"></p>
							<md-icon>download</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						@md-selected="onSelect"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<div class="md-layout md-gutter md-alignment-space-between">
								<md-field>
									<label for="pages">{{ $t("general.perPage") }}</label>
									<md-select v-model="pagination.perPage" name="pages">
										<md-option
											v-for="item in pagination.perPageOptions"
											:key="item"
											:label="item"
											:value="item">
											{{ item }}
										</md-option>
									</md-select>
								</md-field>
								<div class="md-layout md-gutter" style="justify-content: flex-end">
									<div class="md-layout-item md-size-25">
										<md-field>
											<md-input
												type="search"
												class="mb-3"
												clearable
												style="width: 200px"
												:placeholder="$t('general.search')"
												v-model="filters.query">
											</md-input>
										</md-field>
									</div>
								</div>
							</div>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('usage.stats.label.tenant_name')"
								style="width: 12%"
								class="centered-content bordered-cell"
								><i>{{ item.tenant_name }}</i></md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.customer_name')"
								md-sort-by="name"
								style="width: 12%"
								class="centered-content bordered-cell"
								><b>{{ item.customer_name }}</b></md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.device_qty')"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.device }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.sdwan_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.sdwan_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.shield_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.shield_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.additional_ip_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.additional_ip_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.content_filtering_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.content_filtering_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.chraas_qty')"
								md-sort-by="name"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.chraas_quantity }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('usage.stats.label.timestamp')"
								md-sort-by="name"
								style="width: 16%"
								class="centered-content bordered-cell"
								>{{ item.latest_timestamp }}</md-table-cell
							>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		funcActiveCustomerData() {
			return this.activeCustomerData;
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "customer_name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			filters: {
				status: null,
				query: "",
			},
			footerTable: [
				"Tenant",
				"Customer",
				"Device Quantity",
				"SD-WAN Quantity",
				"Shield Quantity",
				"Additional IP Quantity",
				"Content Filtering Quantity",
			],
			propsToSearch: ["tenant_name", "customer_name"],
			tableData: [],
			searchedData: [],
			activeCustomerData: [],
			activeCustomerData30d: [],
			fuseSearch: null,
			deviceGroups: [],
			selectedDevices: [],
			timer: null,
			copiedId: null,
			copiedIp: "",
		};
	},
	methods: {
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["tenant_name", "customer_name"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			// Get data from API
			let userData = TokenService.getUser();
			const stats = await evoapi.get("/usage_stats/customers/days/0").then((response) => {
				this.tableData = response.data;
				this.searchedData = this.tableData;
			});
			const stats30d = await evoapi.get("/usage_stats/customers/days/30").then((response) => {
				this.tableData30d = response.data;
			});
		},
		async fetchDataActiveCustomer() {
			// Get data from API
			let userData = TokenService.getUser();
			const stats = await evoapi
				.get("/usage_stats/customer/" + userData.customerId + "/days/0")
				.then((response) => {
					this.activeCustomerData = response.data;
				});
			const stats30d = await evoapi
				.get("/usage_stats/customer/" + userData.customerId + "/days/30")
				.then((response) => {
					this.activeCustomerData30d = response.data;
				});
		},
		onSelect(items) {
			this.selectedDevices = [];
			items.forEach((item) => {
				this.selectedDevices.push(item.device_id);
			});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		downloadCsv(data, days) {
			if (!data || data.length === 0) {
				alert("Nessun dato disponibile per il download!");
				return;
			}

			if (data === "active-customer") {
				if (!this.activeCustomerData || this.activeCustomerData.length === 0) {
					alert("Nessun dato disponibile per il download!");
					return;
				}
			} else if (data === "all-customers") {
				if (!this.tableData || this.tableData.length === 0) {
					alert("Nessun dato disponibile per il download!");
					return;
				}
			}
			// Costruisci l'intestazione CSV (dipende dalla struttura dei tuoi dati)
			const csvRows = [
				[
					"Tenant Name",
					"Customer Name",
					"Device Qty",
					"SD-WAN Qty",
					"Shield Qty",
					"Additional IP Qty",
					"Content Filtering Qty",
					"CHRaaS Qty",
					"Latest Timestamp",
				], // aggiungi altre intestazioni se necessario
			];
			if (data === "active-customer") {
				if (days === 0) {
					// Aggiungi i dati
					this.activeCustomerData.forEach((item) => {
						csvRows.push(
							[
								item.tenant_name,
								item.customer_name,
								item.device,
								item.sdwan_quantity,
								item.shield_quantity,
								item.additional_ip_quantity,
								item.content_filtering_quantity,
								item.chraas_quantity,
								item.latest_timestamp,
							].join(",")
						);
					});
				} else if (days === 30) {
					// Aggiungi i dati
					this.activeCustomerData30d.forEach((item) => {
						csvRows.push(
							[
								item.tenant_name,
								item.customer_name,
								item.device,
								item.sdwan_quantity,
								item.shield_quantity,
								item.additional_ip_quantity,
								item.content_filtering_quantity,
								item.chraas_quantity,
								item.latest_timestamp,
							].join(",")
						);
					});
				}
			} else if (data === "all-customers") {
				if (days === 0) {
					this.tableData.forEach((item) => {
						csvRows.push(
							[
								item.tenant_name,
								item.customer_name,
								item.device,
								item.sdwan_quantity,
								item.shield_quantity,
								item.additional_ip_quantity,
								item.content_filtering_quantity,
								item.chraas_quantity,
								item.latest_timestamp,
							].join(",")
						);
					});
				} else if (days === 30) {
					this.tableData30d.forEach((item) => {
						csvRows.push(
							[
								item.tenant_name,
								item.customer_name,
								item.device,
								item.sdwan_quantity,
								item.shield_quantity,
								item.additional_ip_quantity,
								item.content_filtering_quantity,
								item.chraas_quantity,
								item.latest_timestamp,
							].join(",")
						);
					});
				}
			}

			// Crea la stringa CSV
			const csvString = csvRows.join("\n");

			// Crea un Blob con dati CSV
			const blob = new Blob([csvString], { type: "text/csv" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			//today date in format YYYY-MM-DD
			const today = new Date();
			const year = today.getFullYear(); // Restituisce l'anno in formato YYYY
			const month = today.getMonth() + 1; // Incrementa di 1 per avere il mese da 1 a 12
			const day = today.getDate(); // Restituisce il giorno del mese da 1 a 31
			// Formatta mese e giorno per avere sempre due cifre
			const formattedMonth = month.toString().padStart(2, "0");
			const formattedDay = day.toString().padStart(2, "0");

			if (data === "active-customer") {
				a.download = year + "-" + formattedMonth + "-" + formattedDay + "_usage-stats_" + data + "-data.csv";
			} else if (data === "all-customers") {
				a.download = year + "-" + formattedMonth + "-" + formattedDay + "_usage-stats_" + data + "-data.csv";
			}
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		},
	},
	mounted() {
		this.hasPermission("usage:stats_all-customers") && this.fetchData();
		this.fetchDataActiveCustomer();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.icon-online {
	color: #4caf50 !important;
}
.icon-warning {
	color: #ff9800 !important;
}
.icon-danger {
	color: #dc3545 !important;
}
.button-warning {
	background-color: #ff9800 !important;
}
</style>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
	overflow-x: hidden;
}

.paginated-table.md-table {
	padding: 20px 20px 20px 20px;
}

.md-table {
	overflow-x: hidden;
	text-align: center;
}

.md-table-head {
	text-align: center;
	font-size: 20px;
}

.md-table .md-table-head:last-child {
	text-align: center;
	overflow-x: hidden;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-table-head-container {
	text-align: center;
}

// .paginated-table {
// 	text-align: center;
// }
// .md-ripple {
// 	text-align: center;
// }

.table-stats {
	display: flex;
	align-items: center;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}

.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}

.copy-paste-password {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: large;
	text-align: center;
}

.md-button.spacing-both {
	margin-left: 5px;
	margin-right: 5px;
}

.clipboard-tooltip {
	position: absolute;
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	/* ... altri stili per il tooltip ... */
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}

@media only screen and (min-width: 1281px) {
	.hide-on-large {
		display: none !important;
	}
}
</style>
